import { useWindowEventListener } from './useWindowEventListener'

export function useDocumentVisibility() {
  const [documentIsVisible, setDocumentIsVisible] = React.useState(true)

  React.useEffect(
    () => { setDocumentIsVisible(document.visibilityState === 'visible') },
    []
  )

  useWindowEventListener('visibilitychange', () => {
    setDocumentIsVisible(document.visibilityState === 'visible')
  })

  return documentIsVisible
}
