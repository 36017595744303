import { FloatingOverlay, useFloating, FloatingFocusManager, useRole, useTransitionStyles, useInteractions, FloatingPortal } from '@floating-ui/react'
import { setOnScreensaverOpen, useScreensaver, useScreensaverStateChanged } from '/features/pageOnly/screensaver/screenSaverControls'
import { useDocumentVisibility } from '/machinery/useDocumentVisibility'
import { useSpring } from '@react-spring/web'
import { useElementSize } from '@kaliber/use-element-size'
import { useTranslate } from '/machinery/I18n'
import { useEvent } from '/machinery/useEvent'
import { BreathingCircles } from '/features/pageOnly/screensaver/BreathingCircles'
import { ShadowCasting } from '/features/pageOnly/ShadowCasting'
import { AudioToggle } from '/features/pageOnly/screensaver/buildingBlocks/AudioToggle'
import { Icon } from '/features/buildingBlocks/Icon'
import { trackInteraction } from '/machinery/tracking/pushToDataLayer'

import styles from './Screensaver.css'

import closeIcon from '/images/icons/close.raw.svg'

import audioSrcNight from '/features/pageOnly/screensaver/audio-night.mp3'
import audioSrcDay from '/features/pageOnly/screensaver/audio-day.mp3'

export function Screensaver({ lateNight }) {
  const [muted, setMuted] = React.useState(false)
  const documentIsVisible = useDocumentVisibility()
  const [open, setOpen] = useScreenSaverState()

  useScreensaver()

  const {
    floatingProps,
    isMounted,
    context,
  } = useFloatingModal({ open: open && documentIsVisible, onOpenChange: setOpen })

  return (
    <div className={styles.component}>
      <BackgroundAudio playing={open && !muted} onError={handleMute} {...{ lateNight }} />

      <FloatingPortal id='screensaver-root'>
        {isMounted && (
          // eslint-disable-next-line @kaliber/layout-class-name
          <FloatingOverlay className={styles.overlay} lockScroll>
            <FloatingFocusManager {...{ context }}>
              <div
                data-context-late-night={String(lateNight)}
                className={styles.floating}
                {...floatingProps}
              >
                <ScreensaverImpl
                  onClose={() => setOpen(false)}
                  onMutedChange={() => setMuted(!muted)}
                  layoutClassName={styles.screensaverLayout}
                  {...{ muted }}
                />
              </div>
            </FloatingFocusManager>
          </FloatingOverlay>
        )}
      </FloatingPortal>
    </div>
  )

  function handleMute() {
    setMuted(true)
  }
}

function useScreenSaverState() {
  const [open, setOpen] = React.useState(false)

  setOnScreensaverOpen({
    callback: () => (
      setOpen(true),
      trackInteraction({
        type: 'screensaver',
        title: 'screensaver',
        action: `user ${open ? 'inactive' : 'active'}`,
      })
    )
  })

  const screenSaverStateChanged = useScreensaverStateChanged()

  return /** @type {const} */ ([
    open,
    React.useCallback(
      open => {
        setOpen(open)
        screenSaverStateChanged({ open })
      },
      [screenSaverStateChanged]
    )
  ])
}

function ScreensaverImpl({ onClose, muted, onMutedChange, layoutClassName }) {
  const { __ } = useTranslate()
  const { ref: sizeRef, size } = useElementSize()

  return (
    <div className={cx(styles.componentImpl, layoutClassName)}>
      <ShadowCasting layoutClassName={styles.shadowLayout} />

      <strong className={styles.title}>
        {__`screensaver-title-line-1`}<br />
        {__`screensaver-title-line-2`}
      </strong>

      <p className={styles.subtitle}>
        {__`screensaver-subtitle`}
      </p>

      <AudioToggle
        enabled={!muted}
        onClick={() => onMutedChange(!muted)}
        layoutClassName={styles.audioToggleLayout}
      />

      <button className={styles.closeOverlay} onClick={onClose} onTouchMove={onClose} aria-hidden type='button' />

      <p className={cx(styles.exitHint, styles.pointer)}>{__`screensaver-exit-desktop`}</p>
      <p className={cx(styles.exitHint, styles.touch)}>{__`screensaver-exit-mobile`}</p>

      <div ref={sizeRef} className={styles.visual}>
        {Boolean(size.width && size.height) && (
          <BreathingCircles width={size.width} height={size.height} />
        )}
      </div>

      <button
        type='button'
        onClick={onClose}
        className={styles.close}
        data-x='click-to-close-screensaver'
        aria-label={__`screensaver-close`}
      >
        <Icon icon={closeIcon} layoutClassName={styles.iconLayout} />
      </button>
    </div>
  )
}

function BackgroundAudio({ playing, lateNight, onError }) {
  const documentIsVisible = useDocumentVisibility()
  const [audio, setAudio] = React.useState(null)
  const errorEvent = useEvent(onError)

  const [{ volume }, api] = useSpring(() => ({
    volume: audio && playing && documentIsVisible ? 1 : 0,
    onChange: ({ value }) => {
      audio.volume = value.volume
    },
    onRest({ value }) {
      if (value.volume === 0) audio?.pause()
    },
    config: {
      duration: playing ? 2000 : 350
    }
  }), [playing, documentIsVisible, audio])

  React.useEffect(
    () => {
      if (!playing) return

      const a = audio ?? new Audio(lateNight ? audioSrcNight : audioSrcDay)

      if (!documentIsVisible) {
        a.pause()
        return
      }

      Promise.resolve(a.play()).then(
        () => { a.volume = volume.get() },
        errorEvent
      )

      setAudio(a)
    },
    [audio, playing, volume, api, errorEvent, documentIsVisible, lateNight]
  )

  return null
}

function useFloatingModal({ open, onOpenChange }) {
  const { refs, context } = useFloating({
    open,
    onOpenChange
  })

  const role = useRole(context)

  const { isMounted, styles: transitionStyles } = useTransitionStyles(context, {
    duration: open ? 2000 : 500,
    initial: {
      opacity: 0
    },
  })

  const { getFloatingProps } = useInteractions([
    role,
  ])

  return {
    floatingProps: {
      ref: refs.setFloating,
      style: transitionStyles,
      ...getFloatingProps(),
    },
    isMounted,
    context,
  }
}
