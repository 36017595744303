import { sequence, lerp, unlerp } from '@kaliber/math'
import { animated, useSpring, to, config } from '@react-spring/web'

import styles from './AudioToggle.css'

export function AudioToggle({ enabled, onClick, layoutClassName }) {
  const samples = 20
  const { amplitude, phase, setHovered } = useAnimations({ enabled })

  return (
    <button
      data-x={`click-to-${enabled ? 'disable' : 'enable'}-audio`}
      className={cx(styles.component, layoutClassName)}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      onClick={e => { e.stopPropagation(); onClick(e) }}
      onFocus={e => e.target.blur()}
    >
      <span className={styles.label}>Turn sound {enabled ? 'off' : 'on'}</span>
      <svg width={56} height={30} viewBox='0 0 56 30'>
        {sequence(samples).map(x => {
          const n = unlerp({ start: 0, end: samples + 1, input: x })
          const step = unlerp({ start: 0, end: samples + 1, input: 1 })
          return (
            <animated.line
              key={n}
              stroke='currentColor'
              strokeWidth='1px'
              x1={56 * n}
              y1={to([phase, amplitude], (p, a) => calculateY(n, p, a))}
              x2={56 * (n + step)}
              y2={to([phase, amplitude], (p, a) => calculateY(n + step, p, a))}
            />
          )
        })}
      </svg>
    </button >
  )
}

function calculateY(normalizedX, p, a) {
  const normalizedWalkingAmplitude = unlerp({
    start: lerp({ start: 0.1, end: 0.6, input: normalizedX, clamp: true }) - 0.1,
    end: lerp({ start: 0.1, end: 0.6, input: normalizedX, clamp: true }) + 0.4,
    input: a,
    clamp: true
  })
  const amplitude = lerp({ start: 0, end: 1, input: normalizedWalkingAmplitude, clamp: true })
  const phase = normalizedX + p
  const frequency = Math.PI * 4
  const normalizedY = unlerp({ start: -1, end: 1, input: toFixed(amplitude * Math.cos(phase * frequency), 4) })

  return lerp({ start: 5, end: 25, input: normalizedY })
}

function useAnimations({ enabled }) {
  const [hovered, setHovered] = React.useState(false)
  const { amplitude } = useSpring({ amplitude: enabled ? 1 : 0, config: config.slow })
  const { phase } = useSpring({
    from: { phase: 0 },
    to: { phase: 1 },
    loop: true,
    config: {
      easing: t => t,
      duration: hovered ? 3000 : 6000
    }
  })

  return { amplitude, phase, setHovered }
}

function toFixed(n, decimals) {
  const m = Math.pow(10, decimals)
  return Math.floor(n * m) / m
}
